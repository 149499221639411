.piano-88 {
  padding: 20px;
  background-color: #334454;
}

.white-key {
  fill: #FFF;
  transition: 0.1s;
  opacity: 0.5;
}

/* .white-key:hover {
  fill: #AFF;
} */

/* .white-key:active {
  fill: #5DD;
} */

.black-key {
  fill: #000;
  transition: 0.1s;
  opacity: 0.0;
}

/* .black-key:hover {
  fill: #338;
} */

/* .black-key:active {
  fill: #44B;
} */
